/* PROGRAM-COMPONENT */
.program-component .frame-94 {
    align-items: flex-start;
    background-color: var(--white);
    display: flex;
    flex: 0 0 auto;
    gap: 60px;
    padding: 75px 224px;
    position: relative;
}

.program-component .frame-631015 {
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 36px;
    position: relative;
    width: 70%;
}

.program-component .frame-6309 {
    align-items: center;
    align-self: stretch;
    border: 1px solid;
    border-color: var(
        --material-themerefneutral-variantneutral-variant90
    );
    border-radius: 8px;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 20px;
    padding: 24px 32px;
    position: relative;
}

.program-component .program-detail-title {
    align-self: stretch;
    letter-spacing: 0;
    line-height: 32px;
    margin-top: -1px;
    position: relative;
}

.program-component .frame-631014 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 40px;
    justify-content: center;
    position: relative;
}

.program-component .frame-631012 {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 8px;
    position: relative;
}

.program-component .frame-6310 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    position: relative;
}

.program-component .check {
    height: 20px;
    position: relative;
    width: 20px;
}

.program-component .program-detail-item {
    letter-spacing: 0.5px;
    line-height: 26px;
    margin-top: -1px;
    position: relative;
}

.program-component .aa {
    align-items: flex-start;
    background-color: var(--white);
    border: 1px solid;
    border-color: var(
        --material-themerefneutral-variantneutral-variant90
    );
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow: hidden;
    padding: 24px;
    position: relative;
    width: 25%;
}

.program-component .frame-48-1 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 20px;
    position: relative;
}

.program-component .btn-4 {
    align-items: center;
    border-radius: 8px;
    gap: 10px;
    padding: 20px 32px;
    position: relative;
    cursor: pointer;
}
  
.program-component .btn-2 {
    background-color: var(--material-themekey-colorsprimary);
    display: flex;
    flex: 1;
    flex-grow: 1;
}

.program-component .sign-up-2 {
    flex: 1;
    margin-top: -1px;
    position: relative;
}

.program-component .valign-text-middle {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.program-component .sign-up-4 {
    letter-spacing: 0.1px;
    line-height: 20px;
    text-align: center;
}

.program-component .frame-49-1 {
    align-items: center;
    align-self: stretch;
    border: 1px solid;
    border-color: var(--material-themekey-colorsprimary);
    border-radius: 8px;
    display: flex;
    flex: 0 0 auto;
    gap: 20px;
    position: relative;
}
  
.program-component .btn-3 {
    display: flex;
    flex: 1;
    flex-grow: 1;
}
  
.program-component .sign-up-3 {
    color: var(--material-themekey-colorsprimary);
    flex: 1;
    font-family: var(--font-family-circular_std-bold);
    font-size: var(--font-size-s);
    font-weight: 700;
    margin-top: -1px;
    position: relative;
}
/* PROGRAM-COMPONENT */
  



@media only screen and (max-width: 1024px) {
    /* PROGRAM-COMPONENT */
    .program-component .frame-94 {
        padding: 56px 140px;
    }
    /* PROGRAM-COMPONENT */
}

@media only screen and (max-width: 768px) {
    /* PROGRAM-COMPONENT */
    .program-component .frame-94 {
        flex-direction: column-reverse;
        padding: 56px 18px;
    }
    .program-component .frame-631015 {
        width: 100%;
    }
    .program-component .aa {
        width: 100%;
        padding: 0px;
    }
    .program-component .frame-48-1 {
        margin: 20px 20px 0px 20px;
    }
    .program-component .frame-49-1 {
        margin: 0px 20px 20px 20px;
    }
    /* PROGRAM-COMPONENT */
}
