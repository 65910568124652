:root {
    --neutral-variant95: #ecf1f9;
    --bahama-blue: #fff;
    --bahama-blue-2: #fff;
    --material-themeblack: #000000;
    --material-themekey-colorsprimary: #1da1f2;
    --material-themekey-colorstertiary: #50dcaa;
    --material-themereferrorerror0: #000000;
    --material-themereferrorerror100: #ffffff;
    --material-themerefneutral-variantneutral-variant0: #000000;
    --material-themerefneutral-variantneutral-variant100: #ffffff;
    --material-themerefneutral-variantneutral-variant90: #dee3eb;
    --material-themerefneutral-variantneutral-variant98: #f7f9ff;
    --material-themerefneutral-variantneutral-variant99: #fcfcff;
    --material-themerefneutralneutral0: #000000;
    --material-themerefneutralneutral100: #ffffff;
    --material-themerefneutralneutral95: #f0f0f4;
    --material-themerefneutralneutral99: #fcfcff;
    --material-themerefneutralneutralnan: #ffffff;
    --material-themerefprimaryprimary0: #000000;
    --material-themerefprimaryprimary10: #1B0C27;
    --material-themerefprimaryprimary100: #ffffff;
    --material-themerefprimaryprimary20: #003352;
    --material-themerefprimaryprimary2020: #E7F5E0;
    --material-themerefprimaryprimary30: #004a75;
    --material-themerefprimaryprimary50: #007dc0;
    --material-themerefprimaryprimary60: #0098e7;
    --material-themerefprimaryprimary6060: #662D91;
    --material-themerefprimaryprimary80: #95ccff;
    --material-themerefprimaryprimary90: #cde5ff;
    --material-themerefprimaryprimary95: #e8f2ff;
    --material-themerefprimaryprimary98: #f7f9ff;
    --material-themerefprimaryprimary99: #fcfcff;
    --material-themerefsecondarysecondary0: #000000;
    --material-themerefsecondarysecondary100: #ffffff;
    --material-themerefsecondarysecondary20: #233240;
    --material-themerefsecondarysecondary30: #3a4857;
    --material-themerefsecondarysecondary50: #6a7989;
    --material-themerefsecondarysecondary60: #8392a3;
    --material-themerefsecondarysecondary70: #9dadbe;
    --material-themerefsecondarysecondary80: #b9c8da;
    --material-themerefsecondarysecondary90: #d5e4f6;
    --material-themerefsecondarysecondary95: #e8f2ff;
    --material-themerefsecondarysecondary98: #f7f9ff;
    --material-themerefsecondarysecondary99: #fcfcff;
    --material-themereftertiarytertiary0: #000000;
    --material-themereftertiarytertiary100: #ffffff;
    --material-themereftertiarytertiary90: #72fac6;
    --material-themesourceprimary: #1da1f2;
    --material-themesourceseed: #1da1f2;
    --material-themesourcetertiary: #50dcaa;
    --material-themesurfaceslightsurface1: #fcfcff;
    --material-themesurfaceslightsurface2: #fcfcff;
    --material-themesurfaceslightsurface3: #fcfcff;
    --material-themesurfaceslightsurface4: #fcfcff;
    --material-themesurfaceslightsurface5: #fcfcff;
    --material-themesysdarkon-primary: #003352;
    --material-themesysdarkon-primary-container: #cde5ff;
    --material-themesysdarkon-secondary: #233240;
    --material-themesysdarkon-secondary-container: #d5e4f6;
    --material-themesysdarkon-tertiary-container: #72fac6;
    --material-themesysdarkprimary: #95ccff;
    --material-themesysdarkprimary-container: #004a75;
    --material-themesysdarksecondary: #b9c8da;
    --material-themesysdarksecondary-container: #3a4857;
    --material-themesysdarkshadow: #000000;
    --material-themesysdarksurface-tint: #95ccff;
    --material-themesysdarksurface-tint-color: #95ccff;
    --material-themesyslightbackground: #fcfcff;
    --material-themesyslightinverse-on-surface: #f0f0f4;
    --material-themesyslightinverse-primary: #95ccff;
    --material-themesyslighton-error: #ffffff;
    --material-themesyslighton-primary: #ffffff;
    --material-themesyslighton-primary-container: #001d32;
    --material-themesyslighton-secondary: #ffffff;
    --material-themesyslighton-tertiary: #ffffff;
    --material-themesyslightprimary-container: #cde5ff;
    --material-themesyslightsecondary-container: #d5e4f6;
    --material-themesyslightshadow: #000000;
    --material-themesyslightsurface: #fcfcff;
    --material-themesyslightsurface-variant: #dee3eb;
    --material-themesyslighttertiary-container: #72fac6;
    --material-themewhite: #ffffff;
    --manatee: #8c9198;
    --black-coral-pearl: #51606f;
    --solitude: #e8f6fe;
    --white: #ffffff;
    --key-benefit-1: #cde5ff3d;
    --key-benefit-2: #ffffff00;
    --red-bg-color: #ffe8ee;
    --purple-bg-color: #ede2f6;
    --purple-2-bg-color: #F1E8F8;
    --green-bg-color: #e7f5e0;
    --prussian-blue: rgba(0, 51, 82, 1);
    --ebony-clay: rgba(35, 50, 64, 1);
    --venice-blue: rgba(0, 87, 135, 1);
    --regal-blue: rgba(0, 74, 117, 1);
    --oxford-blue: rgba(58, 72, 87, 1);
    --regent-gray: rgba(131, 146, 163, 1);
    --dodger-blue: rgba(29, 161, 242, 1);
    --tint-1: rgba(234, 207, 255, 1);

    --font-size-12px: 12px;
    --font-size-xxs: 12px;
    --font-size-22px: 22px;
    --font-size-l: 20px;
    --font-size-m: 18px;
    --font-size-s: 16px;
    --font-size-xl: 24px;
    --font-size-xs: 14px;
    --font-size-xxl: 28px;
    --font-size-xxxl: 36px;
    --font-size-45px: 45px;

    --font-family-circular_std-bold: 'Circular Std-Bold', Helvetica;
    --font-family-circular_std-black: 'Circular Std-Black', Helvetica;
    --font-family-circular_std-medium: 'Circular Std-Medium', Helvetica;
    --font-family-gilroy-regular: 'Gilroy-Regular', Helvetica;
    --font-family-gilroy-semibold: 'Gilroy-Semibold', Helvetica;
    --font-family-sharp_sans-bold: 'Sharp Sans-Bold', Helvetica;
    --font-family-graphik-regular: 'Graphik-Regular', Helvetica;
    --font-family-graphik-semibold: 'Graphik-SemiBold', Helvetica;
    --font-family-sharp_sans-medium: 'Sharp Sans-Medium', Helvetica;
    --font-family-gilroy-medium: 'Gilroy-Medium', Helvetica;
    --font-family-gilroy-bold: "Gilroy-Bold", Helvetica;
}

a {
    outline: none !important;
    text-decoration: none !important;
}

.circularstd-bold-prussian-blue-36px {
    color: var(--material-themerefprimaryprimary20);
    font-family: var(--font-family-circular_std-bold);
    font-size: var(--font-size-xxxl);
    font-style: normal;
    font-weight: 700;
}

.circularstd-medium-prussian-blue-20px {
    color: var(--material-themerefprimaryprimary20);
    font-family: var(--font-family-circular_std-medium);
    font-size: var(--font-size-l);
    font-style: normal;
    font-weight: 500;
}

.circularstd-medium-red-20px {
    color: var(--material-themerefprimaryprimary6060);
    font-family: var(--font-family-circular_std-medium);
    font-size: var(--font-size-l);
    font-style: normal;
    font-weight: 500;
}

.circularstd-bold-coconut-36px {
    color: var(--material-themerefneutral-variantneutral-variant99);
    font-family: var(--font-family-circular_std-bold);
    font-size: var(--font-size-xxxl);
    font-style: normal;
    font-weight: 700;
}

.circularstd-bold-white-16px {
    color: var(--material-themereferrorerror100);
    font-family: var(--font-family-circular_std-bold);
    font-size: var(--font-size-s);
    font-style: normal;
    font-weight: 700;
}

.gilroy-medium-oxford-blue-16px {
    color: var(--material-themerefsecondarysecondary30);
    font-family: var(--font-family-gilroy-regular);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 137.5% */
    letter-spacing: 0.25px;
}

.gilroy-medium-black-coral-pearl-12px {
    color: var(--black-coral-pearl);
    font-family: var(--font-family-gilroy-medium);
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
}

.gilroy-medium-themerefprimaryprimary6060-12px {
    color: var(--material-themerefprimaryprimary6060);
    font-family: var(--font-family-gilroy-medium);
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
}

.circularstd-bold-prussian-blue-45px {
    color: var(--material-themerefprimaryprimary20);
    font-family: var(--font-family-circular_std-bold);
    font-size: 45px;
    font-style: normal;
    font-weight: 700;
}

.circularstd-bold-prussian-blue-24px {
    color: var(--material-themerefprimaryprimary20);
    font-family: var(--font-family-circular_std-bold);
    font-size: var(--font-size-xl);
    font-style: normal;
    font-weight: 700;
}

.circularstd-black-primary10-45px-w900 {
    color: var(--material-themerefprimaryprimary10);
    font-family: var(--font-family-circular_std-black);
    font-size: var(--font-size-45px);
    font-style: normal;
    font-weight: 900;
    letter-spacing: 0;
    line-height: 52px;
}

.gilroy-medium-oxford-blue-18px {
    color: var(--material-themerefsecondarysecondary30);
    font-family: var(--font-family-gilroy-medium);
    font-size: var(--font-size-m);
    font-style: normal;
    font-weight: 500;
}

.gilroy-bold-oxford-blue-18px {
    color: var(--material-themerefsecondarysecondary30);
    font-family: var(--font-family-gilroy-bold);
    font-size: var(--font-size-m);
    font-style: normal;
    font-weight: 700;
}
.material-themeoverline {
    font-family: var(--font-family-gilroy-semibold);
    font-size: var(--font-size-12px);
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.5px;
}

.material-themebodylarge {
    font-family: var(--font-family-gilroy-regular);
    font-size: var(--font-size-m);
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.5px;
}

.material-themeheadlinesmall {
    font-family: var(--font-family-gilroy-regular);
    font-size: var(--font-size-xl);
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0;
}

.material-themetitlemedium {
    font-family: var(--font-family-gilroy-semibold);
    font-size: var(--font-size-s);
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.15px;
}

.material-themetitlelarge {
    font-family: var(--font-family-gilroy-semibold);
    font-size: var(--font-size-22px);
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0;
}

.circularstd-medium-white-24px {
    color: var(--material-themerefprimaryprimary10);
    font-family: var(--font-family-circular_std-medium);
    font-size: var(--font-size-xl);
    font-weight: 500;
    font-style: normal;
}

.circularstd-book-normal-gull-gray-36px {
    color: var(--material-themerefsecondarysecondary70);
    font-family: var(--font-family-circular_std-medium);
    font-size: var(--font-size-xxxl);
    font-weight: 400;
    font-style: normal;
}

.bg-purple {
    background-color: var(--purple-bg-color);
}

.bg-green {
    background-color: var(--green-bg-color);
}
.bg-red {
    background-color: var(--red-bg-color);
}

@media only screen and (max-width: 768px) {
    .circularstd-medium-white-24px {
        font-size: var(--font-size-s);
    }
}