:root {
  /* VARS */
  /* FONT SIZES */
  /* COLORS */
  --color-black: #0b0d17;
  --color-white: #fff;
  --color-pale-lilac: #d0d6f9;
  --color-grey: #383b4b;

  /* FONTS */
  --font-family-1: 'Bellefair', serif;
  --font-family-2: 'Barlow Condensed', sans-serif;
  --font-family-3: 'Barlow', sans-serif;
}
