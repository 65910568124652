*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

body {
  box-sizing: border-box;
}

html {
  font-size: 10px; /* 1rem = 10px 10px/16px=0.625 */
}
