.about-us-components .frame-98 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  gap: 56px;
  padding: 75px 224px;
  position: relative;
  background-color: var(--white);
  background: linear-gradient(
    180deg,
    var(--key-benefit-1) 0%,
    var(--key-benefit-2) 100%
  );
  /* width: 100%; */
}

.about-us-components .frame-54 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 8px;
  position: relative;
  width: 40%;
}

.about-us-components .frame-152 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 40px;
  position: relative;
  width: 60%;
}

.about-us-components .sub-tittle {
  align-self: stretch;
  letter-spacing: 0;
  line-height: 44px;
  margin-top: -1px;
  position: relative;
}

.about-us-components .easy-donateorg-was {
  align-self: stretch;
  letter-spacing: 0.5px;
  line-height: 26px;
  margin-top: -1px;
  position: relative;
}

.about-us-components .the-easy-donate-star {
  letter-spacing: 0.5px;
  line-height: 26px;
  position: relative;
  /* width: 393px; */
}

.about-us-components .image-69-mob {
  max-inline-size: 100%;
  block-size: auto;
  object-fit: contain;
}

.banner-section-no-button {
    background: #F1E8F8;
    max-height: 400px;
    background-size: cover;
    width: 100%;
    display: flex;
    align-items: center;
    margin: auto;
    position: relative;
}

.banner-section-no-button .container {
  align-items: flex-start;
  display: flex;
  gap: 131px;
  width: 100%;
  justify-content: flex-start;
  padding: 108px 16px 144px 200px;
}

@media only screen and (max-width: 768px) {
  .about-us-components .sub-tittle {
    text-align: center;
  }
  .about-us-components .frame-54,
  .about-us-components .frame-152 {
    width: 100%;
  }
  .about-us-components .frame-98 {
    flex-direction: column;
    padding: 56px 18px;
  }
  .about-us-components .the-easy-donate-star {
    display: none;
  }

  .banner-section-no-button {
    background: #F1E8F8;
    min-height: 500px;
    background-size: cover;
    width: 100%;
    display: flex;
    align-items: center;
    margin: auto;
    position: relative;
  }

  .banner-section-no-button .container {
    align-items: center;
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 64px 16px;
  }
}
