@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");

@font-face {
  font-family: "Circular Std-Bold";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/CircularStd-Bold.otf") format("opentype");
}
@font-face {
  font-family: "Circular Std-Black";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/CircularStd-Black.otf") format("opentype");
}
@font-face {
  font-family: "Circular Std-Medium";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/circular-std-medium-500.ttf") format("truetype");
}
@font-face {
  font-family: "Gilroy-Regular";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/Radomir Tinkov - Gilroy-Regular.otf") format("opentype");
}
@font-face {
  font-family: "Gilroy-Semibold";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/Gilroy-Semibold.otf") format("opentype");
}
@font-face {
  font-family: "Sharp Sans-Bold";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/SharpSans-Bold.otf") format("opentype");
}
@font-face {
  font-family: "Graphik-Regular";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/graphik-regular.otf") format("opentype");
}
@font-face {
  font-family: "Graphik-SemiBold";
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/Graphik-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Sharp Sans-Medium";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/SharpSans-Medium.otf") format("opentype");
}
@font-face {
  font-family: "Gilroy-Medium";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/gilroy-medium.ttf") format("truetype");
}
@font-face {
  font-family: "Gilroy-Bold";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/RadomirTinkov-Gilroy-Bold.otf") format("opentype");
}
.valign-text-middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

* {
  box-sizing: border-box;
}