/* BANNER */
.box {
    width: 100%;
}

#images-1, #images-2 {
    display: block;
}
  
.box .aaa {
    display: block;
    align-items: flex-start;
    gap: 56px;
    top: 0;
    left: 0;
    border-radius: 5px;
    overflow: hidden;
}
  
.box .property-default,
.box .property {
    position: relative;
    width: 100%;
    height: 666px;
    background-size: cover;
    background-position: 50% 50%;
}
  
.box .overlap-group {
    position: relative;
    height: 100%;
}
  
.box .image {
    position: absolute;
    top: 0;
}
  
.box .img {
    left: 0;
    position: absolute;
    top: 0;
}
  
.box .rectangle {
    position: absolute;
    width: 100%;
    height: 666px;
    top: 0;
    left: 0;
    background: linear-gradient(180deg, rgb(0, 51, 82) 42.72%, rgba(0, 51, 82, 0) 100%);
}
  
.box .frame {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    position: absolute;
    width: 40%;
    top: 133px;
    left: 145px;
}
  
.box .div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    position: relative;
    flex: 0 0 auto;
}
  
.box .text-wrapper {
    position: relative;
    margin-top: -1px;
    font-family: "Circular Std-Black", Helvetica;
    font-weight: 900;
    color: #1B0C27;
    font-size: 45px;
    letter-spacing: 0;
    line-height: 52px;
}

.box .text-wrapper-easy-donate {
    position: relative;
    margin-top: -1px;
    font-family: "Circular Std-Black", Helvetica;
    font-weight: 900;
    color: #662D91;
    font-size: 45px;
    letter-spacing: 0;
    line-height: 52px;
}
  
.box .p {
    position: relative;
    align-self: stretch;
    font-family: "Gilroy-Medium", Helvetica;
    font-weight: 500;
    color: #1B0C27;
    font-size: 20px;
    letter-spacing: 0.25px;
    line-height: 30px;
}
  
.box .frame-wrapper {
    display: inline-flex;
    align-items: flex-start;
    gap: 21px;
    position: relative;
    flex: 0 0 auto;
}
  
.box .frame-2 {
    display: inline-flex;
    align-items: center;
    gap: 20px;
    flex: 0 0 auto;
    bottom: 80px;
    left: 145px;
    /* position: fixed; */
    z-index: 999;
}
  
.box .frame-3 {
    position: relative;
    width: 259px;
    height: 259px;
    top: 29px;
    left: 68%;
    border-radius: 235px;
    border: 7px solid;
    border-color: #022d48;
    background-image: url('../../assets/images/slides-1-1.png');
    background-size: cover;
    background-position: 50% 50%;
}
  
.box .frame-4 {
    position: absolute;
    width: 273px;
    height: 273px;
    top: 29px;
    left: 80%;
    border-radius: 235px;
    border: 7px solid;
    border-color: #022d48;
    background-image: url('../../assets/images/slides-1-2.png');
    background-size: cover;
    background-position: 50% 50%;
}
  
.box .frame-5 {
    position: absolute;
    width: 387px;
    height: 387px;
    top: 251px;
    left: 75%;
    border-radius: 235px;
    border: 7px solid;
    border-color: #022d48;
    background-image: url('../../assets/images/slides-1-3.png');
    background-size: cover;
    background-position: 50% 50%;
}
  
.box .frame-6 {
    position: absolute;
    width: 348px;
    height: 348px;
    top: 261px;
    left: 60%;
    border-radius: 235px;
    border: 7px solid;
    border-color: #022d48;
    background-image: url('../../assets/images/slides-1-4.png');
    background-size: cover;
    background-position: 50% 50%;
}

.box .banner-image {
    position: absolute;
    right: 0px;
    top: 50px;
    margin-right: 40px;
    width: unset;
    z-index: 1;
}
  
.box .frame-8 {
    position: absolute;
    width: 40px;
    height: 40px;
    top: 313px;
    left: 52px;
}
  
.box .frame-9 {
    display: flex;
    flex-direction: column;
    width: 40%;
    align-items: flex-start;
    gap: 40px;
    position: absolute;
    top: 159px;
    left: 145px;
}
  
.box .frame-10 {
    position: absolute;
    width: 259px;
    height: 259px;
    top: 29px;
    left: 68%;
    border-radius: 235px;
    border: 7px solid;
    border-color: #022d48;
    background-image: url('../../assets/images/slides-2-1.png');
    background-size: cover;
    background-position: 50% 50%;
}
  
.box .frame-11 {
    position: absolute;
    width: 273px;
    height: 273px;
    top: 29px;
    left: 80%;
    border-radius: 235px;
    border: 7px solid;
    border-color: #022d48;
    background-image: url('../../assets/images/slides-2-2.png');
    background-size: cover;
    background-position: 50% 50%;
}
  
.box .frame-12 {
    position: absolute;
    width: 387px;
    height: 387px;
    top: 251px;
    left: 75%;
    border-radius: 235px;
    border: 7px solid;
    border-color: #022d48;
    background-image: url('../../assets/images/slides-2-3.png');
    background-size: cover;
    background-position: 50% 50%;
}
  
.box .frame-13 {
    position: absolute;
    width: 348px;
    height: 348px;
    top: 261px;
    left: 60%;
    border-radius: 235px;
    border: 7px solid;
    border-color: #022d48;
    background-image: url('../../assets/images/slides-2-4.png');
    background-size: cover;
    background-position: 50% 50%;
}


.box .owl-dots {
    position: absolute;
    bottom: 10px; /* Ajusta la distancia desde la parte inferior según sea necesario */
    left: 50%;
    transform: translateX(-50%);
}
.box .banner_decor_1 {
    width: 302px;
    position: absolute;
    top: 0px;
    left: 0px;
}
.box .banner_decor_2 {
    width: 126px;
    height: 73px;
    position: absolute;
    left: 38%;
    bottom: 46px;
}
.box .banner_decor_3 {
    width: 44px;
    height: 44px;
    position: absolute;
    top: 5%;
    right: 2%;
}
/* BANNER */

/* WHO WE SERVE */
.who-we-serve {
    align-items: center;
    display: flex;
    gap: 40px;
    height: 324px;
    justify-content: center;
    position: relative;
    width: 100%;
}

.who-we-serve .frame-631037 {
    align-items: flex-start;
    align-self: stretch;
    background-color: var(--material-themerefprimaryprimary6060);
    border: 2px solid;
    border-color: var(--material-themerefprimaryprimary10);
    border-radius: 8px;
    box-shadow: 0px 5px 5px #00000040;
    cursor: pointer;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 10px;
    overflow: hidden;
    padding: 32px 33px;
    position: relative;
    max-width: 440px;
}


.who-we-serve .frame-631039 {
    align-items: flex-start;
    align-self: stretch;
    border: 1px solid;
    border-color: var(--material-themerefsecondarysecondary80);
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 10px;
    overflow: hidden;
    padding: 32px 33px;
    position: relative;
    max-width: 440px;
}

.who-we-serve .frame-631030-1 {
    background-color: var(--material-themerefsecondarysecondary95);
    border-radius: 24px;
    height: 56px;
    overflow: hidden;
    position: relative;
    width: 56px;
}

.who-we-serve .foreign-nonprofits {
    align-self: stretch;
    color: var(--material-themerefprimaryprimary20);
    font-family: var(--font-family-circular_std-bold);
    font-size: var(--font-size-xl);
    font-weight: 700;
    letter-spacing: 0;
    line-height: 44px;
    margin-top: -1px;
    position: relative;
}

.who-we-serve .frame-631030 {
    background-color: var(--white);
    border-radius: 24px;
    height: 56px;
    overflow: hidden;
    position: relative;
    width: 56px;
}

.who-we-serve .school {
    height: 40px;
    left: 8px;
    position: absolute;
    top: 8px;
    width: 40px;
}

.who-we-serve .frame-54-1 {
    align-items: flex-start;
    align-self: stretch;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 8px;
    width: 100%;
}

.who-we-serve .frame-54-4 {
    display: flex;
    position: relative;
}
  
.who-we-serve .community-colleges {
    align-self: stretch;
    color: var(--white);
    font-family: var(--font-family-circular_std-bold);
    font-size: var(--font-size-xl);
    font-weight: 700;
    letter-spacing: 0;
    line-height: 44px;
    margin-top: -1px;
    position: relative;
}

.who-we-serve .the-easy-donate-star {
    align-self: stretch;
    color: var(--white);
    font-family: var(--font-family-gilroy-medium);
    font-size: var(--font-size-m);
    font-weight: 500;
    letter-spacing: 0.5px;
    line-height: 26px;
    position: relative;
}

.who-we-serve .the-easy-donate-star-1 {
    letter-spacing: 0.5px;
    line-height: 26px;
    position: relative;
    font-size: var(--font-size-m);
    font-weight: 500;
    align-self: stretch;
}

.who-we-serve .frame {
    height: 32px;
    position: relative;
    width: 32px;
}
/* WHO WE SERVE */

/* TESTIMONIALS */
.as-someone-who-is-b {
    color: var(--material-themerefprimaryprimary10);
    font-family: var(--font-family-gilroy-medium);
    font-size: var(--font-size-xl);
    font-weight: 500;
    letter-spacing: 0;
    line-height: 32px;
    position: relative;
    text-align: center;
    width: 716px;
}
/* TESTIMONIALS */

/* STORIES OF IMPACT */
.stories-of-impact .frame-95-1 {
    align-items: center;
    background: linear-gradient(180deg, rgba(205, 229, 255, 0.24) 0%, rgba(255, 255, 255, 0) 100%);
    border-style: none;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.stories-of-impact .frame-1 {
    align-items: center;
    border: 1px none;
    display: flex;
    width: auto;
}

.stories-of-impact .frame-54 {
    align-items: center;
    border: 1px none;
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 473.6px;
    justify-content: center;
    position: relative;
    width: 550px;
}

.stories-of-impact .place {
    letter-spacing: 0;
    line-height: 44px;
    width: 40vh;
}

.stories-of-impact .number {
    align-self: start;
    padding-left: 80px;
}

.stories-of-impact .number-1 {
    align-self: start;
    padding-left: 60px;
}

.stories-of-impact .enable-everyone-to-i {
    color: var(--material-themerefsecondarysecondary30);
    font-weight: 400;
    line-height: 26px;
    width: 40vh;
}

#image-69-mob, #image-70-mob {
    display: none;
}

#image-69-web, #image-70-web  {
    display: block;
}

.stories-of-impact .image-69 {
    aspect-ratio: 640/473;
    width: 35%;
    max-width: 35%;
    object-fit: cover;
}

.stories-of-impact .vision {
    letter-spacing: 0;
    line-height: 44px;
    width: 430px;
}
.stories-of-impact .build-a-state-of-the {
    color: var(--material-themerefsecondarysecondary30);
    font-weight: 400;
    line-height: 26px;
    width: 430px;
}
  
.stories-of-impact .help-enterprises-and {
    color: var(--material-themerefsecondarysecondary30);
    font-weight: 400;
    line-height: 26px;
    width: 430px;
}

.stories-of-impact .rectangle-25 {
    background-color: var(--material-themerefsecondarysecondary80);
    border: 1px none;
    height: 4px;
    left: 0;
    position: absolute;
    top: 0;
    width: 200px;
}

.stories-of-impact .rectangle-25-1 {
    background-color: var(--material-themerefsecondarysecondary80);
    border: 1px none;
    height: 4px;
    left: 440px;
    position: absolute;
    top: 469px;
    width: 200px;
}

.stories-of-impact .rectangle-26 {
    background-color: var(--material-themerefsecondarysecondary80);
    border: 1px none;
    height: 4px;
    left: -98px;
    position: absolute;
    top: 98px;
    transform: rotate(-90deg);
    width: 200px;
}

.stories-of-impact .rectangle-26-1 {
    background-color: var(--material-themerefsecondarysecondary80);
    border: 1px none;
    height: 4px;
    left: 538px;
    position: absolute;
    top: 371px;
    transform: rotate(-90deg);
    width: 200px;
}
/* STORIES OF IMPACT */


@media only screen and (max-width: 1440px) {
    /* BANNER */
    .box .banner-image {
        width: 42%;
        top: 150px;
    }
    .box .frame,
    .box .frame-9 {
        top: 109px;
        left: 90px;
    }
    .box .frame-2 {
        left: 90px;
    }
    /* BANNER */

}

@media only screen and (max-width: 768px) {
    /* BANNER */
    .box .property-default,
    .box .property {
        height: 1014px;
    }

    .box .frame-3,
    .box .frame-4,
    .box .frame-5,
    .box .frame-6,
    .box .frame-10,
    .box .frame-11,
    .box .frame-12,
    .box .frame-13 {
        display: none;
    }

    .box .frame-9, 
    .box .frame {
        top: 100px;
        left: 10px;
        width: 90%;
    }


    .box .frame-2 {
        position: unset;
    }
    .box .overlap-group {
        display: flex;
        flex-direction: column-reverse;
        padding: 24px 24px 42px 24px;
        gap: 32px;
    }
    .box .frame,
    .box .frame-9 {
        flex: 1;
        min-height: unset;
        position: unset;
        justify-content: space-between;
    }
    .box .banner-image {
        display: flex;
        position: unset;
        margin-right: unset;
        width: unset;
    }
    .box .banner_decor_2 {
        left: unset;
        right: 8%;
    }
    .box .banner_decor_3 {
        width: 32px;
        height: 32px;
        top: 2%;
        right: 2%;
    }
    /* BANNER */

    /* WHO WE SERVE */
    .who-we-serve {
        align-items:flex-start;
        display: inline;
        height: 800px;
    }

    .who-we-serve .frame-631037,
    .who-we-serve .frame-631039 {
        margin-top: 40px;
        min-height: 330px;
    }
    /* WHO WE SERVE */

    /* TESTIMONIALS */
    .as-someone-who-is-b {
        font-size: var(--font-size-m);
        width: 100%;
    }
    /* TESTIMONIALS */

    /* STORIES OF IMPACT */
    .stories-of-impact .frame-95-1 {
        align-items: center;
        align-self: auto;
        display: block;
        flex-direction: column;
        padding: 10px 10px;
    }
    
    .stories-of-impact .frame-54 {
        border: 0px none;
        height: auto;
        gap: 0px;
        width: 100%;
    }

    #image-69-mob, #image-70-mob {
        display: block;
    }

    #image-69-web, #image-70-web {
        display: none;
    }

    .stories-of-impact .rectangle-25, 
    .stories-of-impact .rectangle-26,
    .stories-of-impact .rectangle-25-1,
    .stories-of-impact .rectangle-26-1 {
        display: none;
    }
    
    .stories-of-impact .image-69-mob{
        max-inline-size: 100%;
        block-size: auto;
        object-fit: contain;
    }
    
    
    .stories-of-impact .vision {
        white-space: normal;
        width: 100%;
    }
    
    .stories-of-impact .build-a-state-of-the,
    .stories-of-impact .help-enterprises-and,
    .stories-of-impact .place, 
    .stories-of-impact .enable-everyone-to-i {
        white-space: normal;
        width: 100%;
    }
    
    .stories-of-impact .frame-1 {
        padding: 20px 10px;
    }

    .stories-of-impact .number,
    .stories-of-impact .number-1 {
        align-self: start;
        padding: 0px 0px 20px 0px;
    }
    /* STORIES OF IMPACT */
}

@media only screen and (max-width: 560px) {
    /* BANNER */
    .box .property-default,
    .box .property {
        height: 894px;
    }
    /* BANNER */
}

@media only screen and (max-width: 320px) {
    /* BANNER */
    .box .property-default,
    .box .property {
        height: 893px;
    }
    /* BANNER */
}
