.frame-111 {
    align-items: center;
    display: inline-flex;
    margin: 20px 40px 20px 0px;
    flex: 0 0 auto;
    gap: 5px;
    position: relative;
}

.valign-text-bottom {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.frame-95-1 {
    align-items: center;
    background: linear-gradient(180deg, rgba(205, 229, 255, 0.24) 0%, rgba(255, 255, 255, 0.00) 100%);
    border-style: none;
    display: flex;
    flex-direction: column;
    padding: 100px 0px;
    width: 100%;
}

.frame-1 {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    gap: 32px;
    align-self: stretch;
    flex-wrap: wrap;
}

.card {
    align-items: flex-start;
    background-color: transparent;
    border-radius: 5px;
    border: 1px solid #DEE3EB;
    display: flex;
    flex-direction: column;
    height: 500px;
    width: 340px;
    padding: 16px;
    position: relative;
}

.card .schedule {
    width: 16px;
    height: 16px;
}

.border-unselected {
    border: 1px solid var(--material-themesyslightsurface-variant);
}
.border-selected {
    border: 1px solid var(--material-themerefprimaryprimary6060);
}

.frame-95-1 .frame-9 {
}
.card .arrow-img {
    position: absolute;
    width: 48px;
    height: 48px;
    top: 12px;
    left: 284px;
}

.frame-95-1 .frame-9 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.frame-13 {
    align-items: center;
    display: flex;
    gap: 4px;
    padding-top: 15px;
    padding-left: 15px;
    min-width: 300px;
}

.frame-54 {
    align-items: flex-start;
    border: 1px none;
    display: flex;
    flex-direction: row;
    gap: 32px;
}

.frame-8 {
    align-items: start;
    margin: 16px 20px 5px 20px;
    display: flex;
    flex-direction: column;
}

/* ini Program Highlights */
.frame-111-11 {
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 40px;
    padding: 75px 224px;
    position: relative;
    width: 100%;
}

.frame-631029 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-wrap: wrap;
    gap: 48px 48px;
    position: relative;
    width: 100%;
}

.frame-631024 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 12px;
    position: relative;
    width: 100%;
}

.frame-63103 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 24px;
    position: relative;
    width: 368px;
}

.learn-by-doing-with {
    align-self: stretch;
    letter-spacing: 0.5px;
    line-height: 26px;
    position: relative;
}

@media only screen and (max-width: 768px) {
    .frame-54 {
        flex-direction: column;
    }

    .frame-111-11 {
        gap: 0px;
        padding: 75px 24px;
        position: relative;
        width: 100%;
    }

    .frame-631024 {
        align-items: center;
    }
    
    .frame-63103 {
        align-items: center;
    }

    .frame-631029 {
        padding: 50px 0px;
    }

    .learn-by-doing-with {
        text-align: center;
    }
}
