:root {
  --nav-bar-font-color: var(--material-theme-ref-primary-primary-20, #1B0C27);
  --nav-bar: var(--material-theme-ref-primary-primary-100, #fff);
  --drop-down-item-selected: var(
    --material-theme-ref-primary-primary-95,
    #EDE2F6
  );
  --navbar-item-selected: var(--material-theme-source-primary, #662D91);
  --border-button: var(--material-theme-ref-secondary-secondary-80, #b9c8da);
  --nav-bar-font: var(--font-family-circular_std-bold);
  --border-dropdown-color: var(--material-theme-ref-secondary-secondary-95, #E8F2FF);
}

.wrapper {
  background-color: var(--nav-bar);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.01);
  position: sticky;
  top: 0;
  z-index: 100;
}

.wrapper-header {
  display: flex;
  justify-content: space-between;
  background-color: var(--nav-bar);
  width: 85%;
  margin: auto;
  align-items: center;
  padding: 5px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.01);
}

.right-wrapper {
  display: flex;
  align-items: center;
}

.subscribe {
  display: inline-block;
  padding-right: 3em;
  text-align: center;
  font-family: var(--nav-bar-font);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.1px;
}

a {
  text-decoration: none;
  color: var(--nav-bar-font-color);
}

.btn-schedule-call {
  background-color: var(--material-themekey-colorsprimary);
  color: white;
  padding: 10px 25px;
  border: 1px solid var(--border-button);
  border-radius: 8px;
  text-align: center;
  font-family: var(--nav-bar-font);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.1px;
  cursor: pointer;
}

@media (min-width: 860px) and (max-width: 1045px) {
  .btn-schedule-call {
    padding: 10px 5px;
  }
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 5%;
  background-color: var(--nav-bar);
  display: flex;
  justify-content: space-between;
}

.nav-links a {
  color: var(--nav-bar-font-color);
}

.nav-links a:hover,
.subscribe a:hover {
  color: var(--navbar-item-selected);
}

.wrapper .logo {
  max-width: 320px;
}

.wrapper .logo-mobile-navbar {
  display: none;
}

.wrapper .logo img {
  width: 100%;
}

.menu {
  display: flex;
  gap: 1em;
  align-items: center;
  font-family: var(--nav-bar-font);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.1px;
  z-index: 100;
}

.menu li {
  padding: 5px 14px;
}

.dropdown {
  position: absolute;
  display: none;
  top: 30px;
  z-index: 100;
  padding: 8px;
  gap: 8px;
  border-radius: 0px 0px 5px 5px;
  border-right: 1px solid var(--border-dropdown-color);
  border-bottom: 1px solid var(--border-dropdown-color);
  border-left: 1px solid var(--border-dropdown-color);
  background: var(--material-theme-white, #FFF);
  box-shadow: 0px 4px 20px 0px rgba(26, 28, 30, 0.07);
}

.dropdown li + li {
  margin-top: 8px;
}

.dropdown li {
  padding: 12px;
  width: 15em;
  text-align: left;
}

.dropdown li:hover {
  border-radius: 5px;
  background-color: var(--drop-down-item-selected);
}

.home-options {
  position: relative;
}

.home-option-text:hover {
  cursor: pointer;
}

.home-options:hover .dropdown {
  display: block;
}

input[type=checkbox] {
  display: none;
}

.hamburger {
  display: none;
  font-size: 24px;
  user-select: none;
  border-radius: 8px;
  border: 1px solid var(--border-button);
  padding: 8px;
  margin-top: 15px;
  margin-bottom: 15px;
}

@media (min-width: 1281px) {
  .logo-mobile-view {
    display: none;
  }
}

@media (min-width: 860px) {
  .mobile-view {
    display: none;
  }
  .logo-mobile-view-donate {
    display: none;
  }
}

@media (max-width: 860px) {
  .wrapper-header {
    width: 95%;
  }

  .wrapper .logo {
    max-width: 50px;
  }

  .wrapper .logo-web-navbar {
    display: none;
  }

  .wrapper .logo-mobile-navbar {
    display: block;
  }

  .navbar {
    padding: 0;
  }

  .right-wrapper {
    display: none;
  }

  .menu {
    display: none;
    position: absolute;
    background-color: var(--nav-bar);
    right: 0;
    left: 0;
    text-align: center;
    padding: 16px 0;
  }

  .menu li:hover {
    display: inline-block;
    transition: 0.3s ease;
  }

  .menu li + li {
    margin-top: 12px;
  }

  input[type=checkbox]:checked ~ .menu {
    display: block;
  }

  .hamburger {
    display: block;
  }

  .dropdown {
    right: 0%;
    top: 30px;
    transform: translateX(35%);
  }

  .dropdown li:hover {
    background-color: var(--item-selected);
  }

  .elements-right {
    float: right;
  }

  .elements-left {
    float: left;
  }
}
