.frame-47 {
    align-items: center;
    background-color: var(--material-themereferrorerror100);
    border: 1px none;
    gap: 40px;
    padding: 100px 32px;
    max-width: 1280px;
    margin: auto;
}

.frame-31 {
    align-items: center;
    align-self: stretch;
    border: 1px none;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.frame {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 8px;
    min-height: 104px;
    max-width: 700px;
}

.frequently-asked-questions {
    align-self: stretch;
    letter-spacing: 0;
    line-height: 44px;
    text-align: center;
}

.frame-53 {
    border: 1px none;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.accordion {
    width: 100%;
}

.accordion .accordion-title {
    pointer-events: none;
    cursor: pointer;
}

.accordion-title {
    align-items: center;
    align-self: stretch;
    background-color: var(--material-themereferrorerror100);
    border: 1px none;
    display: flex;
    padding: 16px 0px;
    width: 100%;
}

.why-should-my-instit {
    flex: 1;
    letter-spacing: 0;
    line-height: 28px;
}

.accordion-chevron {
    cursor: pointer;
}

.line {
    align-self: stretch;
    height: 1px;
    object-fit: cover;
    width: 100%;
}

.accordion-description {
    align-items: flex-start;
    align-self: stretch;
    background-color: var(--material-themereferrorerror100);
    border: 1px none;
    padding: 0px 120px 16px 0px;
    display: none;
}

.easy-donate-has-a-fr {
    flex: 1;
    font-weight: 400;
    line-height: 26px;
}

.material-themebodylarge {
    font-family: var(--font-family-gilroy-regular);
    font-size: var(--font-size-m);
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.5px;
}

.accordion.active .accordion-description {
    display: flex;
    animation: fade_in_out 0.5s;
}

.customer-testimonials {
    align-items: center;
    background-color: var(--purple-2-bg-color);
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 100px 120px;
    position: relative;
}

.ready-to-get-started {
    align-self: stretch;
    letter-spacing: 0;
    line-height: 44px;
    text-align: center;
    white-space: nowrap;
}

.get-started-for-free {
    align-self: stretch;
    color: var(--material-themerefprimaryprimary10);
    font-weight: 400;
    line-height: 26px;
    text-align: center;
    white-space: nowrap;
}

.frame-55 {
    align-items: center;
    display: flex;
    gap: 32px;
    flex-direction: row;
}

.btn-5 {
    align-items: center;
    background-color: var(--material-themekey-colorsprimary);
    border-radius: 3px;
    display: flex;
    gap: 10px;
    padding: 20px 32px;
}

.inner-page {
    background: #F1E8F8;
    max-height: 400px;
    background-size: cover;
    width: 100%;
    display: flex;
    align-items: center;
    margin: auto;
    position: relative;
}

.inner-page .container {
    align-items: flex-start;
    display: flex;
    gap: 131px;
    width: 100%;
    justify-content: flex-start;
    padding: 64px 16px 100px 200px;
}

.overlap-group4 .frame-96 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.frame-95 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    gap: 32px;
    z-index: 1;
    max-width: 650px;
}

.institutions-can-sta {
    align-self: stretch;
    color: var(--material-themerefsecondarysecondary30);
    font-weight: 400;
    line-height: 26px;
}

.hand {
    display: flex;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 0;
}

/* TESTIMONIALS */
.customer-testimonials-component {
    background-color: var(--purple-2-bg-color);
}

.customer-testimonials-component .slider-wrapper {
    margin: 1rem;
    position: relative;
    overflow: hidden;
    width: 100%;
}

.customer-testimonials-component .slides-container {
    width: 100%;
    display: flex;
    overflow: scroll;
    scroll-behavior: smooth;
    list-style: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.customer-testimonials-component .slide {
    width: 100%;
    height: 100%;
    flex: 1 0 100%;
}

.customer-testimonials-component .container {
    align-items: center;
    border: 1px none;
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 100px 32px;
    position: relative;
    width: 100%;
    max-width: 1280px;
    margin: auto;
}

.customer-testimonials-component .frame {
    align-items: flex-start;
    border: 1px none;
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
}

.customer-testimonials-component .customer-testimonials-1 {
    align-self: stretch;
    letter-spacing: 0;
    line-height: 44px;
    text-align: center;
    z-index: 1;
}

.customer-testimonials-component .here-is-what-some-of {
    align-self: stretch;
    color: var(--material-themerefneutral-variantneutral-variant99);
    font-weight: 400;
    line-height: 26px;
    text-align: center;
    z-index: 1;
}

.customer-testimonials-component .frame-99 {
    align-items: center;
    border: 1px none;
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 100%;
}

.customer-testimonials-component .frame-43 {
    align-items: center;
    border: 1px none;
    border-radius: 5px;
    box-shadow: 0px 2px 2px #00000008;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    padding: 24px 0px;
    width: 100%;
    max-width: 600px;
}

.customer-testimonials-component .frame-98 {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 100%;
}

.customer-testimonials-component .frame-9888 {
    height: max-content;
    max-height: 300px;
}

.customer-testimonials-component .vector {
    height: 56px;
    min-width: 60px;
}

.customer-testimonials-component .easy-donate-is-a-co {
    color: var(--material-themereferrorerror100);
    font-weight: 400;
    line-height: 32px;
    text-align: center;
    padding: 16px;
}

.customer-testimonials-component .name {
    color: var(--material-themereferrorerror100);
    font-weight: 400;
    line-height: 28px;
    text-align: center;
}

.customer-testimonials-component .frame-97 {
    align-items: flex-start;
    border: 1px none;
    display: flex;
    gap: 56px;
}

.customer-testimonials-component .ellipse {
    height: 92px;
    min-width: 92px;
    cursor: pointer;
    border: 5px solid transparent;
}
.customer-testimonials-component .ellipse.active,
.customer-testimonials-component .ellipse:hover {
    border: 5px solid #1da1f2;
    border-radius: 100%;
}

.customer-testimonials-component .frame-2 {
    height: 178px;
    left: 10%;
    position: absolute;
    top: 0;
    width: 294px;
    z-index: 0;
}

.customer-testimonials-component .rectangle-22 {
    /* background-color: var(--material-themerefprimaryprimary90); */
    border: 1px none;
    border-radius: 5px;
}

.customer-testimonials .frame {
    z-index: 1;
}

.customer-testimonials .quote-decor-1{
    width: 302px;
    position: absolute;
    top: 0px;
    right: 0px;
}
.customer-testimonials .quote-decor-2{
    width: 126px;
    height: 73px;
    position: absolute;
    left: 0px;
    top: 50%;
}
/* TESTIMONIALS */


/* OUR TEAM */
.our-team-component-container {
    align-items: flex-start;
    border: 1px none;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px 10px 50px 10px;
}

.our-team-component {
    align-items: center;
    
    border: 1px none;
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 100px 0px;
    /* height: 550px; */
    width: 100%;
}

.our-team-component .our-team-title {
    align-self: center;
    letter-spacing: 0;
    line-height: 44px;
    margin-top: -1.00px;
    text-align: center;
    white-space: nowrap;
}

.our-team-component .the-easy-donate-team {
    align-self: stretch;
    color: var(--material-themerefprimaryprimary10);
    font-weight: 400;
    line-height: 26px;
    text-align: center;
    white-space: wrap;
    max-width: 800px;
}

.our-team-component .frame-164 {
    align-items: flex-start;
    border: 1px none;
    display: flex;
    gap: 40px;
    width: 70%;
    flex-wrap: wrap;
}

.our-team-component .frame-15 {
    align-items: flex-start;
    align-self: stretch;
    border: 1px none;
    border-radius: 8px;
    padding: 20px 16px;
    display: flex;
    flex: 1;
    gap: 40px;
    background-color: #F1E8F8;
    overflow: hidden;
    position: relative;
}

.our-team-component .frame-150 {
    align-items: center;
    border: 1px none;
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 24px;
}

.our-team-component .frame-15-1 {
    border: 1px none;
    border-radius: 125px;
    height: 125px;
    min-width: 125px;
    overflow: hidden;
}

.our-team-component .overlap-group-1 {
    height: 125px;
    position: relative;
    width: 125px;
}

.our-team-component .rectangle-22 {
    /* background-color: var(--material-themerefprimaryprimary90); */
    border: 1px none;
    border-radius: 5px;
    height: 90px;
    left: 0;
    position: absolute;
    top: 0;
    width: 125px;
}

.our-team-component .frame-148 {
    align-items: flex-start;
    align-self: stretch;
    border: 1px none;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.our-team-component .our-team-member-name {
    align-self: stretch;
    color: var(--material-themerefprimaryprimary10);
    font-weight: 400;
    line-height: 24px;
    margin-top: -1.00px;
    text-align: center;
    white-space: nowrap;
}

.our-team-component .our-team-member-description {
    align-self: stretch;
    color: var(--material-themerefprimaryprimary10);
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    white-space: nowrap;
}

.our-team-component .our-team-member-bios {
    align-self: stretch;
    color: var(--material-themerefprimaryprimary10);
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    margin-right: 10px;
    margin-left: 10px;
}
.our-team-component .quote-decor-2 {
    width: 126px;
    height: 73px;
    position: absolute;
    right: -67px;
    top: -22px;
    rotate: 68deg;
}
/* OUR TEAM */



/* NEWSLETTER */
.newsletter-component .frame-960 {
    background-color: var(--white);
}

.newsletter-component .frame-960 .container {
    align-items: center;
    border-style: none;
    display: flex;
    flex-direction: row;
    gap: 40px;
    padding: 108px 16px 144px 200px;
    width: 100%;
    margin: auto;
}

.newsletter-component .frame-48 {
    align-items: center;
    border: 1px none;
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
    word-wrap: none;
    margin: auto;
}


.newsletter-component .fair-prices-no-hidden-fees {
    align-self: stretch;
    letter-spacing: 0;
    line-height: 44px;
    text-align: start;
}

.newsletter-component .we-help-you-create-reusable {
    align-self: stretch;
    font-weight: 400;
    line-height: 26px;
    text-align: start;
}

.newsletter-component .newsletter-field {
    justify-content: center;
    align-items: flex-start;
    padding: 16px;
    width: 100%;
    max-width: 400px;
    background: #ffffff;
    border: 1px solid #b9c8da;
    display: block;
    border-radius: 8px;
    font-family: var(--font-family-gilroy-regular);
    font-size: 16px;
    letter-spacing: 0.5px;
    color: #3a4857 !important;
}

.newsletter-component .btn-6 {
    align-items: center;
    background-color: var(--material-themekey-colorsprimary);
    border: 1px none;
    border-radius: 6px;
    display: flex;
    gap: 10px;
    padding: 20px 32px;
}

.newsletter-component .sign-up {
    letter-spacing: 0.1px;
    line-height: 20px;
    text-align: center;
}

.newsletter-component .frame-540 {
    align-items: center;
    border: 1px none;
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
}

.newsletter-component img {
    width: 60%;
}
/* NEWSLETTER */

/* BECOME-COMPONENT */
.become-component .be-co-frame {
    width: 100%;
    display: table;
    position: relative;
}

.become-component .be-co-frame .become-item {
    display: table-cell;
    padding: 75px 95px;
    position: relative;
}

.become-component .be-co-frame .div-2 {
    gap: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
}

.become-component .be-co-frame .text-wrapper-1 {
    position: relative;
    align-self: stretch;
    margin-top: -1px;
    font-family: var(--font-family-circular_std-bold);
    font-weight: 700;
    color: var(--material-themerefprimaryprimary10);
    font-size: 36px;
    letter-spacing: 0;
    line-height: 44px;
}

.become-component .be-co-frame .become-description {
    position: relative;
    align-self: stretch;
    font-family: var(--font-family-gilroy-medium);
    font-weight: 500;
    color: var(--material-themerefprimaryprimary10);
    font-size: 20px;
    letter-spacing: 0.25px;
    line-height: 30px;
}

.become-component .be-co-frame .become-btn {
    all: unset;
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    gap: 10px;
    padding: 16px 32px;
    position: relative;
    flex: 0 0 auto;
    background-color: var(--material-themerefprimaryprimary6060);
    border-radius: 8px;
}

.become-component .be-co-frame .become-btn:hover {
    background: var(--material-themerefprimaryprimary10);
    transition: background-color 0.5s;
}

.become-component .be-co-frame .text-wrapper-2 {
    position: relative;
    width: fit-content;
    margin-top: -1px;
    font-family: var(--font-family-circular_std-bold);
    font-weight: 700;
    color: var(--material-themewhite);
    font-size: 17px;
    text-align: center;
    letter-spacing: 0.1px;
    line-height: 20px;
    white-space: nowrap;
}

.become-component .be-co-frame .group {
    position: absolute;
    width: 156px;
    height: 134px;
    bottom: 0px;
    right: 30px;
}
/* BECOME-COMPONENT */

/* VISION-AND-MISSION-COMPONENT */
.vision-and-mission-component .vi-mi-frame {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    padding: 100px 224px;
    position: relative;
    background-color: var(--material-themewhite);
    overflow: hidden;
}

.vision-and-mission-component .vi-mi-frame .div-1 {
    align-items: center;
    gap: 72px;
    align-self: stretch;
    width: 100%;
    flex: 0 0 auto;
    display: flex;
    position: relative;
}

.vision-and-mission-component .vi-mi-frame .div-2 {
    flex-direction: column;
    flex: 1;
    width: 100%;
    height: 440px;
    align-items: center;
    justify-content: center;
    gap: 16px;
    display: flex;
    position: relative;
}

.vision-and-mission-component .vi-mi-frame .rectangle-1 {
    top: 0;
    left: 0;
    position: absolute;
    width: 200px;
    height: 4px;
    background-color: var(--tint-1);
}

.vision-and-mission-component .vi-mi-frame .rectangle-2 {
    bottom: 0px;
    right: 0px;
    position: absolute;
    width: 200px;
    height: 4px;
    background-color: var(--tint-1);
}

.vision-and-mission-component .vi-mi-frame .rectangle-3 {
    top: 100px;
    left: -100px;
    transform: rotate(90deg);
    position: absolute;
    width: 200px;
    height: 4px;
    background-color: var(--tint-1);
}

.vision-and-mission-component .vi-mi-frame .rectangle-4 {
    bottom: 100px;
    right: -100px;
    transform: rotate(90deg);
    position: absolute;
    width: 200px;
    height: 4px;
    background-color: var(--tint-1);
}

.vision-and-mission-component .vi-mi-frame .group-1 {
    position: relative;
    width: 293.04px;
    height: 252px;
}

.vision-and-mission-component .vi-mi-frame .div-3 {
    flex-direction: column;
    align-items: center;
    gap: 52px;
    flex: 1;
    flex-grow: 1;
    display: flex;
    position: relative;
}

.vision-and-mission-component .vi-mi-frame .div-4 {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;
    width: 100%;
    flex: 0 0 auto;
    display: flex;
    position: relative;
}

.vision-and-mission-component .vi-mi-frame .text-wrapper-title {
    position: relative;
    align-self: stretch;
    margin-top: -1px;
    font-family: var(--font-family-circular_std-bold);
    font-weight: 700;
    color: var(--material-themerefprimaryprimary10);
    font-size: 36px;
    letter-spacing: 0;
    line-height: 44px;
}

.vision-and-mission-component .vi-mi-frame .mission-vision-p {
    position: relative;
    align-self: stretch;
    font-family: var(--font-family-gilroy-medium);
    font-weight: 500;
    color: var(--material-themerefprimaryprimary10);
    font-size: 20px;
    letter-spacing: 0.25px;
    line-height: 30px;
}

.vision-and-mission-component .vi-mi-frame .mission-vision-span {
    font-family: var(--font-family-gilroy-medium);
    font-weight: 500;
    color: var(--material-themerefprimaryprimary10);
    font-size: 20px;
    letter-spacing: 0.25px;
    line-height: 30px;
}

.vision-and-mission-component .vi-mi-frame .mission-vision-img {
    position: absolute;
    width: 523px;
    height: 449px;
    right: 0px;
    bottom: -50px;
}
/* VISION-AND-MISSION-COMPONENT */

/* OUR-PARTNERS-COMPONENT */
.opc {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 45px;
    padding: 100px 224px;
    position: relative;
    background-color: #fafafa;
}
.opc .frame-1 {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    flex: 0 0 auto;
    display: flex;
    position: relative;
}
.opc .our-partners-wrapper {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    width: 100%;
    flex: 0 0 auto;
    display: flex;
    position: relative;
}
.opc .our-partners {
    position: relative;
    align-self: stretch;
    margin-top: -1px;
    font-family: var(--font-family-circular_std-bold);
    font-weight: 700;
    color: var(--material-themerefprimaryprimary10);
    font-size: 36px;
    text-align: center;
    letter-spacing: 0;
    line-height: 44px;
}
.opc .div-1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 32px;
    position: relative;
    align-self: stretch;
    width: 100%;
    flex: 0 0 auto;
}
.opc .frame-wrapper {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 16px;
    align-self: stretch;
    width: 100%;
    flex: 0 0 auto;
    display: flex;
    position: relative;
}
.opc .frame-2 {
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    width: 100%;
    flex: 0 0 auto;
    display: flex;
    position: relative;
}
.opc .image-wrapper {
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 20px 8px;
    flex: 1;
    align-self: stretch;
    flex-grow: 1;
    background-color: var(--white);
    border-radius: 11px;
    overflow: hidden;
    border: 1px solid;
    border-color: #3a3c271a;
    display: flex;
    position: relative;
}
.opc .partner-image {
    width: 100%;
    object-fit: fill;
}
/* OUR-PARTNERS-COMPONENT */


/* EXTRA-NAVBAR-COMPONENT */
.extra-navbar {
    width: 100%;
}
.extra-navbar .buttons {
    display: flex;
    justify-content: space-between;
    width: 85%;
    margin: auto;
    padding: 5px;
}
.extra-navbar .frame-4 {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 20px 12px 12px 20px;
    border-radius: 6px;
}
.extra-navbar .frame-1 {
    padding: 12px 0px 12px 12px;
    display: inline-flex;
    gap: 20px;
}
.extra-navbar .div-1 {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 8px 0px;
    position: relative;
    flex: 0 0 auto;
    border-radius: 6px;
}
.extra-navbar .en-img {
    position: relative;
    width: 20px;
    height: 20px;
}
.extra-navbar .text-wrapper {
    position: relative;
    width: fit-content;
    margin-top: -1px;
    font-family: var(--font-family-circular_std-bold);
    font-weight: 700;
    color: var(--material-themerefprimaryprimary10);
    font-size: 14px;
    text-align: center;
    letter-spacing: 0.1px;
    line-height: 20px;
    white-space: nowrap;
}
/* EXTRA-NAVBAR-COMPONENT */

/* BannerSection2 */
.banner-section-2 .overlap-group {
    background-color: var(--purple-2-bg-color);
    position: relative;
}
.banner-section-2 .frame-19 {
    position: relative;
    bottom: 0;
    left: 0;
}
.banner-section-2 .frame-20 {
    display: flex;
    width: 50%;
    align-items: flex-start;
    gap: 54px;
    margin: 10px 10px 10px 15%;
    padding-top: 20px;
    padding-bottom: 200px;
}
.banner-section-2 .text-wrapper-8 {
    position: relative;
    flex: 1;
    margin-top: -1px;
}
.banner-section-2 .frame-14 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    position: relative;
    flex: 1;
    flex-grow: 1;
}
.banner-section-2 .text-wrapper-9 {
    position: relative;
    align-self: stretch;
    margin-top: -1px;
    font-family: var(--font-family-gilroy-medium);
    font-weight: 500;
    color: var(--material-themerefprimaryprimary10);
    font-size: 18px;
    letter-spacing: 0.5px;
    line-height: 26px;
}
/* BannerSection2 */


/* IconsSection */
.icons-overlapping-section .frame-21 {
    display: flex;
    flex-wrap: wrap;
    width: 70%;
    align-items: flex-start;
    gap: 48px 48px;
    position: relative;
    top: -100px;
    left: 224px;
}
.icons-overlapping-section .frame-22 {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    padding: 43px 0px 0px;
    position: relative;
    flex: 1;
    flex-grow: 1;
    background-color: var(--material-themewhite);
    border-radius: 10px 10px 0px 0px;
}
.icons-overlapping-section .icon-wrapper {
    position: relative;
    width: 124px;
    height: 124px;
    border-radius: 24px;
    overflow: hidden;
}
.icons-overlapping-section .img-3 {
    position: absolute;
    width: 100%;
    height: 100%;
}
.icons-overlapping-section .frame-23 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    position: relative;
    align-self: stretch;
    width: 100%;
    flex: 0 0 auto;
}
.icons-overlapping-section .text-wrapper-10 {
    position: relative;
    align-self: stretch;
    margin-top: -1px;
    font-family: var(--font-family-circular_std-bold);
    font-weight: 700;
    color: var(--material-themerefprimaryprimary10);
    font-size: 24px;
    text-align: center;
    letter-spacing: 0;
    line-height: 36px;
}
.icons-overlapping-section .text-wrapper-11 {
    position: relative;
    align-self: stretch;
    font-family: var(--font-family-gilroy-medium);
    font-weight: 500;
    color: var(--material-themerefprimaryprimary10);
    font-size: 18px;
    text-align: center;
    letter-spacing: 0.5px;
    line-height: 26px;
}
/* IconsSection */

@media only screen and (max-width: 1440px) {
    /* VISION-AND-MISSION-COMPONENT */
    .vision-and-mission-component .vi-mi-frame .rectangle-1 {
        width: 100px;
    }
    
    .vision-and-mission-component .vi-mi-frame .rectangle-2 {
        width: 100px;
    }
    
    .vision-and-mission-component .vi-mi-frame .rectangle-3 {
        top: 50px;
        left: -50px;
        width: 100px;
    }
    
    .vision-and-mission-component .vi-mi-frame .rectangle-4 {
        bottom: 50px;
        right: -50px;
        width: 100px;
    }
    .vision-and-mission-component .vi-mi-frame .mission-vision-img {
        width: 323px;
        height: 249px;
    }
    /* VISION-AND-MISSION-COMPONENT */

    /* OUR-PARTNERS-COMPONENT */
    .opc {
        padding: 100px 124px;
    }
    /* OUR-PARTNERS-COMPONENT */
}

@media only screen and (max-width: 1190px) {
    /* IconsSection */
    .icons-overlapping-section .frame-21 {
        left: 120px;
    }
    /* IconsSection */
}

@media only screen and (max-width: 1024px) {
    /* VISION-AND-MISSION-COMPONENT */
    .vision-and-mission-component .vi-mi-frame {
        padding: 100px 112px;
    }
    /* VISION-AND-MISSION-COMPONENT */

    /* TESTIMONIALS */
    .customer-testimonials .quote-decor-2{
        display: none;
    }
    .customer-testimonials .ready-to-get-started {
        color: var(--material-themerefprimaryprimary10);
    }
    /* TESTIMONIALS */

    /* OUR TEAM */
    .our-team-component .our-team-title {
        color: var(--material-themerefprimaryprimary10);
    }
    /* OUR TEAM */

    /* IconsSection */
    .icons-overlapping-section .frame-21 {
        left: 98px;
    }
    /* IconsSection */
}


@media only screen and (max-width: 920px) {
    /* OUR TEAM */
    .our-team-component {
      height: 730px;
    }
    /* OUR TEAM */
}

@media only screen and (max-width: 860px) {
    /* OUR TEAM */
    .our-team-component {
        align-items: center;
        height: 1230px;
        width: 100%;
    }
    .our-team-component .frame-164 {
        flex-direction: column;
        width: auto;
        flex-wrap: nowrap;
    }
    /* OUR TEAM */

    /* VISION-AND-MISSION-COMPONENT */
    .vision-and-mission-component .vi-mi-frame{
        padding: 100px 18px;
    }
    .vision-and-mission-component .vi-mi-frame .mission-vision-img {
        display: none;
    }
    /* VISION-AND-MISSION-COMPONENT */

    /* EXTRA-NAVBAR-COMPONENT */
    .extra-navbar .buttons {
        width: 95%;
    }
    .extra-navbar .frame-1 {
        display: none;
    }
    .extra-navbar .frame-4 {
        padding: 20px 12px 12px 5px;
    }
    /* EXTRA-NAVBAR-COMPONENT */

    /* BannerSection2 */
    .banner-section-2 .frame-20 {
        width: 75%;
    }
    /* BannerSection2 */

    /* IconsSection */
    .icons-overlapping-section .frame-21 {
        width: 100%;
        flex-direction: column;
        align-items: center;
        position: inherit;
    }
    .icons-overlapping-section .icon-wrapper {
        width: 200px;
        height: 200px;
    }
    .icons-overlapping-section .text-wrapper-11 {
        padding-left: 10px;
        padding-right: 10px;
    }
    /* IconsSection */
}

@media only screen and (max-width: 768px) {

    .customer-testimonials {
        padding: 100px 10px;
    }

    .ready-to-get-started {
        white-space: normal;
    }

    .get-started-for-free {
        white-space: normal;
    }
    
    .frame {
        min-height: 100%;
        max-width: 100%;
    }

    .inner-page {
        background: #F1E8F8;
        min-height: 500px;
        background-size: cover;
        width: 100%;
        display: flex;
        align-items: center;
        margin: auto;
        position: relative;
    }

    .inner-page .container {
        align-items: center;
        display: flex;
        width: 100%;
        justify-content: center;
        padding: 64px 16px;
    }

    .hand {
        display: none;
    }
    /* TESTIMONIALS */
    .customer-testimonials-component .frame-97 {
        gap: 35px;
    }
    .customer-testimonials-component .ellipse {
        height: 80px;
        min-width: 80px;
    }
    /* TESTIMONIALS */

    /* NEWSLETTER */
    .newsletter-component .frame-960 .container {
        flex-direction: column;
        gap: 0;
        align-items: center;
        justify-content: center;
        padding: 64px 16px 0px;
    }
    .newsletter-component .frame-48 {
        flex-direction: column;
        width: 100%;
    }
    .newsletter-component .frame-540 {
        margin-top: 30px;
    }
    .newsletter-component .fair-prices-no-hidden-fees {
        text-align: center;
    }

    .newsletter-field {
        max-width: none !important;
        width: 100% !important;
    }

    .newsletter-component .frame-48 {
        align-items: normal;
    }
    /* NEWSLETTER */

    /* BECOME-COMPONENT */
    .become-component .be-co-frame .become-item {
        display: block;
        padding: 40px;
    }
    .become-component .be-co-frame .group {
        display: none;
    }
    /* BECOME-COMPONENT */

    /* VISION-AND-MISSION-COMPONENT */
    .vision-and-mission-component .vi-mi-frame .div-1 {
        flex-direction: column;
    }
    .vision-and-mission-component .vi-mi-frame .group-1 {
        margin: 75px;
    }
    /* VISION-AND-MISSION-COMPONENT */

    /* OUR-PARTNERS-COMPONENT */
    .opc {
        padding: 70px 18px;
    }
    .opc .frame-2 {
        flex-direction: column;
    }
    /* OUR-PARTNERS-COMPONENT */

    /* TESTIMONIALS */
    .customer-testimonials .quote-decor-1{
        width: 133px;
    }
    /* TESTIMONIALS */

    /* OUR TEAM */
    .our-team-component .quote-decor-2 {
        right: -10px;
        top: -10px;
    }
    /* OUR TEAM */

    /* BannerSection2 */
    .banner-section-2 .frame-20 {
        flex-direction: column;
        margin-left: 10px;
        width: 100%;
        padding-bottom: 56px;
    }
    /* BannerSection2 */
}

@media only screen and (max-width: 425px) {
    /* BECOME-COMPONENT */
    .become-component .be-co-frame .become-item {
        padding: 24px;
    }
    /* BECOME-COMPONENT */
}

