/* HOW-IT-WORKS-COMPONENT */
.how-it-works-component .frame-98 {
    align-items: flex-start;
    background-color: var(--white);
    display: flex;
    flex: 0 0 auto;
    gap: 56px;
    padding: 75px 224px;
    position: relative;
    /* width: 100%; */
}

.how-it-works-component .frame-54 {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 8px;
    position: relative;
    width: 40%;
}

.how-it-works-component .how-it-works {
    align-self: stretch;
    letter-spacing: 0;
    line-height: 44px;
    margin-top: -1px;
    position: relative;
}

.how-it-works-component .the-easy-donate-star {
    letter-spacing: 0.5px;
    line-height: 26px;
    position: relative;
    /* width: 393px; */
}

.how-it-works-component .frame-152 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 40px;
    position: relative;
    width: 60%;
}

.how-it-works-component .easy-donateorg-was {
    align-self: stretch;
    letter-spacing: 0.5px;
    line-height: 26px;
    margin-top: -1px;
    position: relative;
}

.how-it-works-component .frame-631018 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 21px;
    position: relative;
}

.how-it-works-component .frame-9 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 20px;
    position: relative;
}

.how-it-works-component .btn-4 {
    align-items: center;
    background-color: var(--material-themekey-colorsprimary);
    border-radius: 8px;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    padding: 20px 32px;
    position: relative;
}

.how-it-works-component .sign-up {
    letter-spacing: 0.1px;
    line-height: 20px;
    margin-top: -1px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
}

.how-it-works-component .valign-text-middle {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
/* HOW-IT-WORKS-COMPONENT */



/* KEY-BENEFITS-COMPONENT */
.key-benefits-component .key-benefits-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    padding: 75px 224px;
    position: relative;
    background-color: var(--white);
    background: linear-gradient(180deg, var(--key-benefit-1) 0%, var(--key-benefit-2) 100%);
}

.key-benefits-component .frame-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    position: relative;
    flex: 0 0 auto;
}

.key-benefits-component .key-benefits {
    align-self: stretch;
    letter-spacing: 0;
    line-height: 44px;
    margin-top: -1px;
    position: relative;
}

.key-benefits-component .div-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    position: relative;
    align-self: stretch;
    width: 100%;
    flex: 0 0 auto;
}

.key-benefits-component .div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    position: relative;
    flex: 0 0 auto;
}

.key-benefits-component .div-2 {
    display: flex;
    align-items: flex-start;
    gap: 24px;
    padding: 32px;
    position: relative;
    align-self: stretch;
    flex: 0 0 auto;
    background-color: #e7fff1;
    border-radius: 5px;
}

.key-benefits-component .benefit-img-wrapper {
    position: relative;
    width: 56px;
    height: 56px;
    background-color: var(--material-themerefsecondarysecondary100);
    border-radius: 24px;
    overflow: hidden;
}

.key-benefits-component .img {
    position: absolute;
    width: 40px;
    height: 40px;
    top: 8px;
    left: 8px;
}

.key-benefits-component .div-3 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    position: relative;
    flex: 1;
    flex-grow: 1;
}

.key-benefits-component .benefit-title {
    position: relative;
    align-self: stretch;
    margin-top: -1px;
    font-family: "Circular Std-Bold", Helvetica;
    font-weight: 700;
    color: var(--material-themerefprimaryprimary20);
    font-size: 24px;
    letter-spacing: 0;
    line-height: 36px;
}

.key-benefits-component .text-wrapper-2 {
    position: relative;
    align-self: stretch;
    font-family: "Gilroy-Medium", Helvetica;
    font-weight: 500;
    color: var(--material-themerefsecondarysecondary30);
    font-size: 18px;
    letter-spacing: 0.5px;
    line-height: 26px;
}

.key-benefits-component .div-4 {
    display: flex;
    align-items: flex-start;
    gap: 24px;
    padding: 32px;
    position: relative;
    align-self: stretch;
    flex: 0 0 auto;
    background-color: var(--material-themerefsecondarysecondary95);
    border-radius: 5px;
}
/* KEY-BENEFITS-COMPONENT */



@media only screen and (max-width: 768px) {
    /* HOW-IT-WORKS-COMPONENT */
    .how-it-works-component .how-it-works {
        text-align: center;
    }
    .how-it-works-component .frame-54,
    .how-it-works-component .frame-152 {
        width: 100%;
    }
    .how-it-works-component .frame-98 {
        flex-direction: column;
        padding: 56px 18px;
    }
    .how-it-works-component .the-easy-donate-star {
        display: none;
    }
    /* HOW-IT-WORKS-COMPONENT */


    /* KEY-BENEFITS-COMPONENT */
    .key-benefits-component .key-benefits-content {
        padding: 56px 18px;
    }
    .key-benefits-component .div-2,
    .key-benefits-component .div-4 {
        flex-direction: column;
    }
    /* KEY-BENEFITS-COMPONENT */
}