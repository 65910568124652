:root {
  --nav-link-font: var(--font-family-gilroy-regular);
}

/* GO TO TOP BUTTON */
.gototop {
  bottom: 0px;
  right: 20px;
  height: 96px;
  position: fixed;
  width: 96px;
  z-index: 99;
  display: none;
  cursor: pointer;
}

/* FOOTER */
.footer {
  background-color: var(--material-themerefprimaryprimary10);
}
.footer .container {
  align-items: center;
  border: 1px none;
  gap: 40px;
  padding: 64px 32px;
  position: relative;
  width: 100%;
  max-width: 1280px;
  margin: auto;
}

.footer .frame-45 {
  align-items: flex-start;
  border: 1px none;
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
}

.footer .logo {
  max-width: 320px;
}

.footer .logo img {
  width: 100%;
}

.footer .frame-54-1 {
  align-items: flex-start;
  border: 1px none;
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  gap: 66px;
  min-width: 167px;
}

.footer .frame-400 {
  align-items: flex-start;
  border: 1px none;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 24px;
  min-width: 50px;
}

.footer .frame-dinamic {
  align-items: flex-start;
  border: 1px none;
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  gap: 66px;
  min-width: 167px;
}

.footer .company {
  color: var(--material-theme-surfaces-light-surface-1, #fcfcff);
  font-family: var(--nav-link-font);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.15px;
}

.footer .about-us {
  color: var(--bahama-blue);
  font-weight: 400;
  line-height: 22px;
  text-align: center;
}

.footer .privacy-policy {
  color: var(--bahama-blue);
  font-weight: 400;
  line-height: 22px;
  text-align: center;
}

.footer .terms-conditions {
  color: var(--bahama-blue);
  font-weight: 400;
  line-height: 22px;
  text-align: center;
}

.footer .support {
  align-self: stretch;
  color: var(--bahama-blue-2);
  font-weight: 400;
  line-height: 24px;
}

.footer .contact-us {
  color: var(--bahama-blue);
  font-weight: 400;
  line-height: 22px;
  text-align: center;
}

.footer .pricing {
  color: var(--bahama-blue);
  font-weight: 400;
  line-height: 22px;
  text-align: center;
}

.footer .quick-links {
  align-self: stretch;
  color: var(--bahama-blue-2);
  font-weight: 400;
  line-height: 24px;
}

.footer .frame-57 {
  align-items: flex-start;
  border: 1px none;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 32px;
  margin: 32px 0px;
}
.footer .frame-57 .line {
  width: 100%;
}

.footer .social {
  align-items: center;
  border: 1px none;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
}

.footer .frame-56 {
  align-items: flex-start;
  border: 1px none;
  display: flex;
  gap: 22px;
}

.footer .facebook {
  height: 24px;
  min-width: 24px;
}

.footer .instagram {
  height: 24px;
  min-width: 24px;
}

.footer .linkedin {
  height: 24px;
  min-width: 24px;
}

.footer .twitter {
  height: 24px;
  min-width: 24px;
}

.footer .youtube {
  height: 24px;
  min-width: 24px;
}

.footer .easy-donate-inc-20 {
  color: var(--material-theme-surfaces-light-surface-2, #fcfcff);
  font-family: var(--nav-link-font);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: 0.25px;
  flex: 1;
  display: flex;
  margin: 0 auto;
}

.footer-link * {
  color: var(--material-theme-surfaces-light-surface-2, #fcfcff);
  text-align: center;
  font-family: var(--nav-link-font);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.25px;
}

.footer-link a:link {
  text-decoration: none;
}

.footer-link a:visited {
  text-decoration: none;
}

.footer-link a:hover {
  text-decoration: none;
}

.footer-link a:active {
  text-decoration: none;
}

@media only screen and (min-width: 767px) {
  .logo-mobile {
    display: none;
  }

  .legal-line {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .footer .legal-line img {
    width: 100%;
    margin-top: 32px;
  }



  .logo-web {
    display: none;
  }

  .footer .logo {
    margin: auto;
  }

  .footer .frame-dinamic {
    align-items: flex-start;
    border: 1px none;
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    gap: 66px;
    min-width: 167px;
    margin-top: 40px;
  }

  .footer .frame-54-1 {
    align-items: flex-start;
    border: 1px none;
    display: inline-block;
    flex: 1;
    gap: 66px;
  }

  .easy-legal-info {
    display: flex;
    align-items: center;
    position : relative;
    text-align : center;
    margin-top: 20px;
  }


  .footer .easy-donate-inc-20 {
    text-align:center; 
    vertical-align: middle;
    display: table-cell;   
  }

  .footer .frame-400-social{
    margin-top: 40px;
  }

}
